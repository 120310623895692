import React, { ReactElement } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import { SignIn } from '@clerk/nextjs';
import { parseSearchParams } from '@utils/url';
import { inBrowser } from '@utils/browser';

import { useAnalytics } from '@hooks/useAnalytics';
import { PublicLayout } from '@components/layouts/AppLayout/Layout';
export default function CustomSignIn() {
  const { page } = useAnalytics();
  const title = 'Sign in | Clerk';
  const searchParams = parseSearchParams(
    inBrowser() ? window.location.search : '',
  );
  const copyrightYear = new Date().getFullYear();

  React.useEffect(() => {
    void page('Dashboard_Sign In');
  }, [page]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property='og:title' content={title} key='title' />
      </Head>
      <div className='relative flex min-h-screen flex-col items-center justify-center pb-4 pt-10 font-sans md:pb-0 md:pt-0'>
        <div className='fixed inset-0 z-0'>
          <Image
            src='/assets/signin-bg.svg'
            alt='sign-in'
            fill
            className='pointer-events-none absolute inset-0 object-cover object-center'
          />
        </div>
        <div className='flex flex-col gap-12'>
          <SignIn
            routing='path'
            path='/sign-in'
            // @ts-ignore
            disabledStrategies={searchParams.getAll('disabled_strategies')}
            appearance={{
              elements: {
                form: 'cl-sign-in-hs-form',
              },
            }}
          />
          <div className='z-1 flex justify-between px-7 py-0 text-white'>
            <div>
              <a href='https://www.clerk.com'>© {copyrightYear} Clerk</a>
            </div>
            <div className='flex items-center gap-2'>
              <a href='https://www.clerk.com/support'>Support</a>
              <a href='https://www.clerk.com/privacy'>Privacy</a>
              <a href='https://www.clerk.com/terms'>Terms</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

CustomSignIn.getLayout = function getLayout(page: ReactElement) {
  return <PublicLayout>{page}</PublicLayout>;
};
